import * as React from 'react';
import {useCallback, useEffect} from 'react';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField, useMediaQuery, useTheme
} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector, useStore} from "react-redux";
import {useForm} from "react-hook-form";
import {sendOneTimePassword, userLogin, validateEmail} from '../../store/auth.js';
import {client} from "../../util/apiClient";
import {styled} from "@mui/material/styles";

import logo from '../../assets/images/logo-no-background.png';

import googleBtn from '../../assets/images/btn_google_signin_light_normal_web@2x.png';
import appleBtn from '../../assets/images/appleid_button@2x.png';

const RootDiv = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,

  color: 'white',  // Setting text color to white

  // MUI TextField/Input borders
  '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },

  '& .MuiTextField-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },

  '& .MuiTextField-root.Mui-focused:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },

  '& .MuiInput-underline:before': {
    borderBottomColor: 'white',
  },

  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },

  // For the label
  '& .MuiInputLabel-outlined.MuiInputLabel-outlined': {
    color: 'white',
  },

  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    color: 'white',
  },

}));

const CenteredGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  // Styles for the img element inside the CenteredGridItem
  '& img': {
    maxWidth: '100%',    // Ensures the image doesn't exceed the grid item's width
    width: '400px',   // Ensures the image doesn't exceed the grid item's height
    display: 'inline-block',    // Removes any default margin around the image
    margin: 'auto',      // Centers the image when it's smaller than the grid item
  }
}));

const ImageButton = styled(Button)(({ theme }) => ({
  padding: 0,
  margin: 0,
  minWidth: 'auto', // Override MUI's default min-width
  '& .MuiButton-label': {
    padding: 0,
    margin: 0,
  },
  '& img': {
    maxHeight: '56px',
    width: 'auto',
  }
}));


const LoginContent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const {register, watch, handleSubmit} = useForm();
  const [openPasswordless, setPasswordless] = React.useState(false);
  const authState = useSelector((state) => state.auth);
  const store = useStore();
  let {token} = useParams();

  useEffect(() => {
    if (token && location.pathname.includes('/login/confirm/') && !store.getState().auth?.validateEmailLoading) {
      dispatch(validateEmail(token)).then((data => {
        if (data?.payload?.success) {
          navigate('/login')
        }
      }));
    }
  }, [token])

  const handlePasswordlessClose = () => {
    setPasswordless(false);
  };

  const sendOneTimePasswordFunc = () => {
    dispatch(sendOneTimePassword({email: watch('email')}));
    setPasswordless(false);
  };

  const onLogin = useCallback((data) => {
    if (data.password === '') {
      setPasswordless(true);
    } else {
      dispatch(userLogin(data));
    }
  }, [dispatch]);

  const handleGoogleLogin = () => {
    client.get('auth/googlelogin', {
      headers: {
        "Access-Control-Allow-Origin": "* ",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    })
      .then((res) => {
        window.location.assign(res.url);
      })
      .catch((err) => console.log(err));
  };

  const handleAppleLogin = () => {
    client.get('auth/applelogin', {
      headers: {
        "Access-Control-Allow-Origin": "* ",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    })
      .then((res) => {
        window.location.assign(res.url);
      })
      .catch((err) => console.log(err));
  };


  useEffect(() => {
    if (authState.user.id && !store.getState().auth?.authLoading) {
      navigate('/run', {replace: true});
    }
  }, [authState, navigate]);

  return (
    <RootDiv>
      <Dialog
        fullScreen={fullScreen}
        open={openPasswordless}
        onClose={handlePasswordlessClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Korištenje jednokratna lozinke?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Želite li se prijaviti jednokratnom lozinkom koja će biti poslana na navedeni mail?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handlePasswordlessClose}>
            Odustani
          </Button>
          <Button onClick={sendOneTimePasswordFunc} autoFocus>
            Pošalji
          </Button>
        </DialogActions>
      </Dialog>
      <Container maxWidth="sm" sx={{mt: 4, mb: 4}}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{minHeight: '50vh'}}
        >
          <Grid item xs={12}>
            <CenteredGridItem item xs={12}>
              <img src={logo} alt="Neurocloud logo" />
            </CenteredGridItem>
            <form className="form" onSubmit={handleSubmit(onLogin)}>
              <h2 style={{textAlign: 'center'}}>Prijava</h2>
              <CenteredGridItem item margin={5} xs={12}>
                <TextField
                  required
                  label="E-mail"
                  {...register('email', {required: true})}
                />
              </CenteredGridItem>
              <CenteredGridItem item margin={5} xs={12}>
                <TextField
                  label="Lozinka"
                  type="password"
                  autoComplete="current-password"
                  {...register('password')}
                  onKeyDown={e => e.key === 'Enter' && handleSubmit(onLogin)}
                />
              </CenteredGridItem>
              <CenteredGridItem item xs={12} mb={10}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  spacing={2}

                >
                  <Button type="submit" variant="contained" color="secondary">Prijava</Button>
                  <Button onClick={() => navigate('/register')} variant="outline" color="secondary">Registracija</Button>
                </Stack>
              </CenteredGridItem>

            </form>
            <CenteredGridItem item xs={12}>
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={2} direction="row"
              >
                <ImageButton onClick={(e) => handleGoogleLogin()}>
                  <img src={googleBtn} alt="Sign-in with Google" />
                </ImageButton>
                <ImageButton onClick={(e) => handleAppleLogin()}>
                  <img style={{maxHeight: '48px',}} src={appleBtn} alt="Sign-in with Google" />
                </ImageButton>
              </Stack>
            </CenteredGridItem>
          </Grid>
        </Grid>
      </Container>
    </RootDiv>
  );
};

export default LoginContent;
