import React, {Suspense} from 'react';
import {
  FaCogs,
} from 'react-icons/fa';
import {Navigate, useRoutes} from 'react-router-dom';
// layouts
import MainLayout from './components/layouts/main';
import SimpleLayout from './components/layouts/simple';
import ProtectedRoute from './components/util/ProtectedRoute';
import PermissionRoute from './components/util/PermissionRoute';
import LoginContent from "./routes/auth/login";
import RegisterContent from "./routes/auth/register";
import Settings from "./routes/Settings";
import UserAddUpdate from "./components/settings/users/UserAddUpdate";
import {useSelector} from "react-redux";
import ActivityAddUpdateView from "./components/settings/activities/ActivityAddUpdateView";
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import ActivityRunView from "./routes/activity_runs/ActivityRunView";
import ActivityRunAddUpdateView from "./routes/activity_runs/ActivityRunAddUpdateView";
import ProgramAddUpdateView from "./components/settings/programs/ProgramAddUpdateView";

// ----------------------------------------------------------------------

export const NavMenuConfig =  () => {
  return [
    {
      title: 'Moja mjerenja',
      path: '/run',
      icon: <DirectionsRunIcon />,
    },
    {
      title: 'Postavke',
      path: '/settings/',
      icon: <FaCogs />,
    },
  ];
}


const Router = () => {
  const permissions = useSelector((state) => state.auth.permissionNames);
  return useRoutes([
    {
      path: 'login',
      element: <Suspense fallback={<></>}> <LoginContent/> </Suspense>,
    },
    {
        path: 'login/confirm/:token',
        element: <Suspense fallback={<></>}> <LoginContent/> </Suspense>
    },
    {
      path: 'register',
      element: <RegisterContent/>,
    },
    {
      path: '/',
      element: <ProtectedRoute><MainLayout/></ProtectedRoute>,
      children: [
        {element: <Navigate to="/run"/>, index: true},
        {path: 'profile/settings', element: <UserAddUpdate profileEdit={true}/>},
        {path: 'settings/:section?', element: <Settings />},
        // User settings
        {path: 'settings/users/add', element: <PermissionRoute permissionName={permissions.userManagement}><UserAddUpdate/></PermissionRoute>},
        {path: 'settings/users/update/:id', element: <PermissionRoute permissionName={permissions.userManagement}><UserAddUpdate/></PermissionRoute>},
        // Activity settings
        {path: 'settings/activities/add', element: <PermissionRoute permissionName={permissions.activityManagement}><ActivityAddUpdateView/></PermissionRoute>},
        {path: 'settings/activities/update/:id', element: <PermissionRoute permissionName={permissions.activityManagement}><ActivityAddUpdateView activityEdit={true}/></PermissionRoute>},
        {path: 'settings/activities/view/:id', element: <PermissionRoute permissionName={permissions.activityManagement}><ActivityAddUpdateView activityView={true}/></PermissionRoute>},
        // Program settings
        {path: 'settings/programs/add', element: <PermissionRoute permissionName={permissions.programManagement}><ProgramAddUpdateView/></PermissionRoute>},
        {path: 'settings/programs/update/:id', element: <PermissionRoute permissionName={permissions.programManagement}><ProgramAddUpdateView programEdit={true}/></PermissionRoute>},
        {path: 'settings/programs/view/:id', element: <PermissionRoute permissionName={permissions.programManagement}><ProgramAddUpdateView programView={true}/></PermissionRoute>},
        // Activity Runs
        {path: 'run', element: <ActivityRunView/>},
        {path: 'run/add', element: <ActivityRunAddUpdateView/>},
        {path: 'run/update/:id', element: <ActivityRunAddUpdateView activityRunEdit={true}/>},
        {path: 'run/view/:id', element: <ActivityRunAddUpdateView activityRunViewOnly={true}/>},
      ]
    },
    {
      element: <SimpleLayout/>,
      children: [
        // {path: '404', element: <Page404/>},
        //redirect all unknown routes to 404
        {path: '*', element: <Navigate to="/404" replace />},
      ],    }
  ]);
};



export default Router;
