import React from 'react';
import {Box, Button, Stack} from '@mui/material';
import {deleteActivityRun, listActivityRuns} from "../../store/activities_runs";
import {useNavigate} from "react-router-dom";
import EntityDataGrid from "../../components/common/EntityDataGrid";
import {deleteUser} from "../../store/users";


const columns =  [
  {
    field: 'time',
    headerName: 'Vrijeme',
    flex: 1,
    editable: false,
    valueFormatter: params => new Date(params?.value).toLocaleString('hr-HR')
  },
  {
    field: 'activity',
    headerName: 'Aktivnost',
    flex: 1,
    editable: false,
    valueGetter: params => params.row['activity.name']
  },
  {
    field: 'user',
    headerName: 'Korisnik',
    flex: 1,
    editable: false,
    valueGetter: params => `${params.row['user.name']} ${params.row['user.surname']}`
  },
  {
    field: 'programs',
    headerName: 'Program',
    flex: 1,
    editable: false,
  },
];

const ActivityRunView = props => {
  const navigate = useNavigate();

  return <Box height={800}>
    <Stack direction='row-reverse'>
      <Button onClick={() => navigate('/run/add')}>Dodaj</Button>
    </Stack>
    <EntityDataGrid
      columns={columns}
      fetchActions={listActivityRuns}
      dataSelector={(state) => state.activityRuns.listData}
      statusSelector={(state) => state.activityRuns.listLoading}
      deleteColumn={true}
      deleteActions={deleteActivityRun}
      deleteDialogConfig={{
        getDeleteDescription: (row) => (`Jeste li sigurni da želite izbrisati unos aktivnosti
        ${row?.row?.activity?.name} u vremenu ${row?.row?.time} korisnika ${row?.row?.user?.name} ${row?.row?.user?.surname}`)
      }}
      viewColumn={true}
      updateColumn={true}
    />
  </Box>;
};

export default ActivityRunView;
