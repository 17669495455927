import React from 'react';
import {Box, Button, Stack} from '@mui/material';
import {listActivities, deleteActivity} from "../../../store/activities";
import {useNavigate} from "react-router-dom";
import EntityDataGrid from "../../common/EntityDataGrid";


const columns =  [
  {
    field: 'name',
    headerName: 'Naziv',
    width: 150,
    editable: false,
  },
  {
    field: 'type',
    headerName: 'Tip',
    width: 150,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Opis',
    width: 400,
    editable: false,
  },
];

const ActivityManagement = props => {
  const navigate = useNavigate();

  return <Box height={800}>
    <Stack direction='row-reverse'>
      <Button onClick={() => navigate('/settings/activities/add')}>Dodaj aktivnost</Button>
    </Stack>
    <EntityDataGrid
      columns={columns}
      fetchActions={listActivities}
      dataSelector={(state) => state.settingsActivities.listData}
      statusSelector={(state) => state.settingsActivities.listLoading}
      viewColumn={true}
      updateColumn={true}
      deleteColumn={true}
      deleteDialogConfig={{
        getDeleteDescription: (row) => (`Jeste li sigurni da želite trajno izbrisati aktivnost - ${row?.row?.name}`)
      }}
      deleteActions={deleteActivity}
    />
  </Box>;
};

export default ActivityManagement;
