import React, {useCallback, useEffect, useState} from 'react';
import {Box, Button, MenuItem, Stack, TextField} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {useDispatch, useSelector, useStore} from "react-redux";
import {listActivities} from "../../store/activities";
import {useForm} from "react-hook-form";
import validator from '@rjsf/validator-ajv8';
import Form from '@rjsf/mui';
import {addUser, getUser, updateUser} from "../../store/users";
import {addActivityRun, getActivityRun, updateActivityRun} from "../../store/activities_runs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from  "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import locale from 'date-fns/locale/hr';



const ActivityRunAddUpdateView  = ({activityRunEdit = false, activityRunViewOnly = false, ...props}) => {
  const navigate = useNavigate();
  const store = useStore()
  const dispatch = useDispatch()
  const authUser = useSelector((state) => state.auth.user)
  const [dateStart, setDateStart] = useState(new Date());
  const [schema, setSchema] = useState({})
  const [formData, setFormData] = useState({})
  const uiSchema = {
    'ui:options': {
      label: true,
    },
  };
  const activities = useSelector((state) => state.settingsActivities.listData);
  const [currentActivity, setCurrentActivity] = React.useState('')
  const {id} = useParams();

  const onSave = useCallback((data) => {
    let send_data = {
      user_id: authUser.id,
      activity_id: currentActivity,
      time: dateStart,
      data: {...data.formData},
    }
    if (id && activityRunEdit) {
      send_data.id = id
      dispatch(updateActivityRun(send_data))
    } else {
      dispatch(addActivityRun(send_data))
    }
  }, [dispatch, currentActivity, activityRunEdit]);

  useEffect(() => {
    if (!store.getState().settingsActivities?.getLoading) {
      dispatch(listActivities());
    }
    if (id && !store.getState().activityRuns?.getLoading) {
      dispatch(getActivityRun({id: id})).then(rsp => {
        setCurrentActivity(rsp.payload.data.activity_id)
        setSchema(rsp.payload.data.activity.run_schema)
        setFormData(rsp.payload.data.data)
        setDateStart(rsp.payload.data.time)
      });
    }
  }, [dispatch, id]);

  return <Box height={800}>
    <Stack direction='row'>
      <Button onClick={() => navigate(-1)}>Nazad</Button>
    </Stack>
    <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
      <Grid
        container
        spacing={0}
        style={{minHeight: '50vh'}}
      >
        <Grid item xs={12} md={6}>
          <Grid item margin={1} xs={12}>
            <TextField
              select
              fullWidth
              label="Aktivnost"
              defaultValue={currentActivity}
              SelectProps={{
                value: currentActivity
              }}
              onChange={(event) => {
                if (event.target?.value) {
                  let _activity = activities.find(x => x.id === event.target.value)
                  setSchema(_activity.run_schema)
                  setCurrentActivity(event.target.value)
                }
              }}
            >
              {
                activities.map(value => (
                  <MenuItem key={String(value.id)} value={String(value.id)}> {String(value.name)} </MenuItem>
                ))
              }
            </TextField>
          </Grid>
          <Grid item margin={1} xs={12}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={locale}
              localeText={{todayButtonLabel: 'Danas', cancelButtonLabel: 'Odustani', okButtonLabel : 'Prihvati'}}
            >
              <DateTimePicker
                value={dateStart}
                label="Vrijeme"
                timezone="Europe/Zagreb"
                inputFormat='dd.MM.yyyy HH:mm'
                onChange={(newValue) => {
                  setDateStart(newValue);
                }}
                inputProps={{
                  placeholder: 'dd.mm.gggg'
                }}
                renderInput={(params) => <TextField fullWidth {...params}
                                                    onFocus={event => {
                                                      event.target.select();
                                                    }}
                />}
                componentsProps={{
                  actionBar: { actions: ['today', 'accept', 'cancel']},
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item margin={1} xs={12}>
            <Form
              schema={schema}
              uiSchema={uiSchema}
              validator={validator}
              onSubmit={onSave}
              onError={() => window.scrollTo(0, 0)}
              formData={formData}
              disabled={activityRunViewOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </Box>
};

export default ActivityRunAddUpdateView;
