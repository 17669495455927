import createBaseStore from './baseStoreFactory';

const store = createBaseStore('activityRuns', {
  listUrl: '/activity/run/list',
  getUrl: '/activity/run/get',
  addUrl: '/activity/run/add',
  updateUrl: '/activity/run/update',
  deleteUrl: '/activity/run/delete',
});

export default store.reducer;
export const listActivityRuns = store.listData;
export const getActivityRun = store.getData;
export const addActivityRun = store.addData;
export const deleteActivityRun = store.deleteData;
export const updateActivityRun = store.updateData;
