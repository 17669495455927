import React from 'react';
import {Box, Button, Stack} from '@mui/material';
import {deleteUser, listUsers} from "../../../store/users";
import {useNavigate} from "react-router-dom";
import EntityDataGrid from "../../common/EntityDataGrid";


const columns =  [
  {
    field: 'name',
    headerName: 'Ime',
    width: 150,
    editable: false,
  },
  {
    field: 'surname',
    headerName: 'Prezime',
    width: 150,
    editable: false,
  },
  {
    field: 'email',
    headerName: 'Elektronička pošta',
    width: 200,
    editable: false,
  },
  {
    field: 'permissions',
    headerName: 'Dozvole',
    width: 200,
    editable: false,
  },
];

const UsersManagement = props => {
  const navigate = useNavigate();

  return <Box height={800}>
    <Stack direction='row-reverse'>
      <Button onClick={() => navigate('/settings/users/add')}>Dodaj korisnika</Button>
    </Stack>
    <EntityDataGrid
      columns={columns}
      fetchActions={listUsers}
      dataSelector={(state) => state.settingsUsers.listData}
      statusSelector={(state) => state.settingsUsers.listLoading}
      deleteActions={deleteUser}
      deleteDialogConfig={{
        getDeleteDescription: (row) => (`Jeste li sigurni da želite izbrisati korisnika - ${row?.row?.name} ${row?.row?.surname} - ${row?.row?.email}`)
      }}
      deleteColumn={true}
      updateColumn={true}
    />
  </Box>;
};

export default UsersManagement;
