import React from 'react';
import PropTypes from 'prop-types';
import {useEffect} from 'react';
import {useLocation, NavLink as RouterLink} from 'react-router-dom';
import {
  Box,
  Drawer,
  Avatar,
  useMediaQuery,
  Container,
  List,
  ListItemAvatar,
  ListItemText,
  ListItemButton
} from '@mui/material';

import {NavMenuConfig} from '../../Router';


const NAV_WIDTH = 280;

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({openNav, onCloseNav}) {
  const {pathname} = useLocation();
  const navConfig = NavMenuConfig()
  const isDesktop = useMediaQuery('(min-width: 1224px)');
  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Container
      disableGutters={true}
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <List disablePadding sx={{p: 1}}>
        {navConfig.map((item, index) => (
          <ListItemButton component={RouterLink} to={item.path} key={item.path}>
            <ListItemAvatar>
              <Avatar>
                {item.icon}
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.title} secondary={item.subtitle}/>

          </ListItemButton>
        ))}
      </List>

      <Box sx={{flexGrow: 1}}/>
    </Container>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: {lg: 0},
        width: {lg: NAV_WIDTH},
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {width: NAV_WIDTH},
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
