// components/EntityDataGrid.js
import React, {useCallback, useEffect} from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack
} from '@mui/material';
import {DataGrid, GridActionsCellItem} from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import {GRID_LOCALE_HR} from "../../locale/dataGridLocaleHR";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate} from "react-router-dom";
import AdvanceDialog from "./AdvanceDialog";
import PageviewIcon from '@mui/icons-material/Pageview';

const EntityDataGrid = props => {
  const {
    columns=[],
    fetchActions,
    dataSelector,
    statusSelector,
    deleteActions,
    deleteDialogConfig,
    deleteColumn=false,
    updateColumn = false,
    viewColumn = false,
    ...otherProps
  } = props;

  const navigate = useNavigate()
  const [rowParams, setRowParams] = React.useState({})
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false)

  const _columns = [...columns, {
    field: 'actions',
    type: 'actions',
    getActions: (params) => [
      deleteColumn ? <GridActionsCellItem icon={<DeleteIcon/>} onClick={() => {
        setRowParams(params)
        setDeleteDialogOpen(true)
      }} label="Delete"/> : <></>,
      updateColumn ? <GridActionsCellItem icon={<EditIcon/>} onClick={() => {
        setRowParams(params)
        navigate("update/" + params.id)
      }} label="Edit"/> : <></>,
      viewColumn ? <GridActionsCellItem icon={<PageviewIcon/>} onClick={() => {
        setRowParams(params)
        navigate("view/" + params.id)
      }} label="View"/> : <></>,
    ],
  }];
  const data = useSelector(dataSelector);
  const loading = useSelector(statusSelector);
  const dispatch = useDispatch();


  const fetchActionsData = useCallback(() => {
    if(!fetchActions || loading) {
      return;
    }
    if (Array.isArray(fetchActions)) {
      fetchActions.forEach((action) => dispatch(action()));
    } else {
      dispatch(fetchActions());
    }
  }, [dispatch])

  const onConfirmDelete = useCallback(() => {
    if(!deleteActions || !rowParams?.id) {
      console.error('deleteActions or rowParams.id is not defined', deleteActions, rowParams?.id)
      return;
    }

    dispatch(
      deleteActions({id: rowParams?.id})
    ).then((response) => {
      if(response?.payload?.success){
        fetchActionsData()
      } else {
        console.error('Error deleting row', response)
      }
    })
  }, [deleteActions, dispatch, fetchActionsData, rowParams?.id])

  useEffect(() => {
    fetchActionsData()
  }, [dispatch]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <AdvanceDialog
        title={deleteDialogConfig?.getDeleteDialogTitle ? deleteDialogConfig?.getDeleteDialogTitle(rowParams) : 'Brisanje'}
        openDialog={deleteDialogOpen}
        setOpenDialog={setDeleteDialogOpen}
        description={deleteDialogConfig?.getDeleteDescription ? deleteDialogConfig.getDeleteDescription(rowParams) : `Želite li izbrisati unos sa brojem - ${rowParams?.id}?`}
        onConfirm={onConfirmDelete}
      ></AdvanceDialog>
      <DataGrid
        rows={data}
        localeText={GRID_LOCALE_HR}
        columns={_columns}
        rowsPerPageOptions={[10, 20, 50]}
        pageSize={50}
        {...otherProps}
      />
    </>
  );
};

export default EntityDataGrid;
