import React from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {loginWithCookies} from '../../store/auth';


const ProtectedRoute = ({children}) => {

  const dispatch = useDispatch();
  const store = useStore();
  const user = useSelector((state) => state.auth.user);

  if (store.getState().auth?.authLoading) {
    return;
  } else if (user?.id) {
    return children;
  } else {
    if (Cookies.get('csrf_access_token')) {
      dispatch(loginWithCookies());
    }
    // user is not authenticated
    return <Navigate to="/login"/>;
  }
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
