import React from 'react';
import {HashRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import 'react-toastify/dist/ReactToastify.css';

import store from './store';
import Router from './Router';
import {ToastContainer} from "react-toastify";


const theme = createTheme({
  palette: {
    primary: {
      main: '#1EBBD7',
    },
    secondary: {
      main: '#d73a1e', // https://m2.material.io/design/color/the-color-system.html#tools-for-picking-colors
    },
    // error: {
    //   main: '#ff0000',
    // },
    // warning: {
    //   main: '#ff9800',
    // },
    // success: {
    //   main: '#4caf50',

  },


});

function App() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <ToastContainer
            position="bottom-left"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover/>
          <HashRouter>
            <Router/>
          </HashRouter>
        </ThemeProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
