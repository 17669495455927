import React from 'react';
import {useDispatch, useSelector, useStore} from 'react-redux';
import {Navigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {loginWithCookies} from '../../store/auth';


const PermissionRoute = ({permissionName, children}) => {
  const store = useStore();
  if (store.getState().auth?.authLoading) {
    return;
  } else if (store.getState().auth?.user?.permissions?.includes(permissionName)) {
    return children;
  } else {
    return <Navigate to="/home"/>;
  }
};

PermissionRoute.propTypes = {
  permissionName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PermissionRoute;
