import React, { useState, useEffect, useCallback } from 'react';
import {
  Box, Select, MenuItem, FormControl, InputLabel, Button, TextField,
  CircularProgress, Checkbox, FormControlLabel, Typography, FormGroup
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import { client } from '../../../util/apiClient';
import {listActivities} from '../../../store/activities';
import {addProgram, getProgram, updateProgram} from "../../../store/programs";
import {useDispatch} from 'react-redux';


const AnalysisManagement = () => {
  const [programs, setPrograms] = useState([]);
  const [selectedProgramId, setSelectedProgramId] = useState('');
  const [cognitiveActivities, setCognitiveActivities] = useState([]);
  const [selectedCognitiveActivityId, setSelectedCognitiveActivityId] = useState('');
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [doRegressionTree, setDoRegressionTree] = useState(true);
  const [doRandomForest, setDoRandomForest] = useState(true);
  const [doNeuralNetwork, setDoNeuralNetwork] = useState(true);

  const dispatch = useDispatch()

  const fetchPrograms = async () => {
    try {
      const response = await client.get('program/list');
      setPrograms(response.data);
    } catch (error) {
      console.error('Error fetching programs', error);
      toast.error('Failed to fetch programs');
    }
  };

  const onAnalyse = useCallback(async () => {
    setLoading(true);
    try {
      const { blob, filename } = await client.post('analytics/program', {
        program_id: selectedProgramId,
        cognitive_activity_id: selectedCognitiveActivityId,
        date_from: dateFrom?.toISOString(),
        date_to: dateTo ? dateTo.toISOString() : (new Date()).toISOString(),
        do_regression_tree: doRegressionTree,
        do_random_forest: doRandomForest,
        do_neural_network: doNeuralNetwork
      });

      if (blob instanceof Blob) {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error('Response is not a Blob');
      }
    } catch (error) {
      console.error('Error downloading the file:', error);
      toast.error('Failed to download file');
    } finally {
      setLoading(false);
    }
  }, [selectedProgramId, selectedCognitiveActivityId, dateFrom, dateTo, doRegressionTree, doRandomForest, doNeuralNetwork]);

  useEffect(() => {
    fetchPrograms();
  }, []);

  useEffect(() => {
    if (selectedProgramId) {
      const fetchActivities = async () => {
        try {
          const programData = await dispatch(getProgram({id: selectedProgramId}));
          const programActivityIds = programData.payload.data.activities.map(a => a.activity_id);
          let activities = await dispatch(listActivities());
          setCognitiveActivities(activities.payload.filter(a => a.type === 'cognitive' && programActivityIds.includes(a.id)));
        } catch (error) {
          console.error('Error fetching cognitive activities', error);
          toast.error('Failed to fetch cognitive activities');
        }
      }
      fetchActivities();

    } else {
      setCognitiveActivities([]);
      setSelectedCognitiveActivityId('');
    }
  }, [dispatch, selectedProgramId]);

  if (loading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <Typography variant='h6' gutterBottom style={{ marginTop: '20px' }}>
          Izvještaj je u izradi.
        </Typography>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box height={800}>
      <Box display='flex' flexDirection='column' gap={1}>

        {/* Program combo */}
        <FormControl fullWidth={false} margin='normal' style={{ width: 400 }}>
          <InputLabel>Program</InputLabel>
          <Select
            value={selectedProgramId}
            onChange={(e) => setSelectedProgramId(e.target.value)}
            label='Program'
          >
            {programs.map(program => (
              <MenuItem key={program.id} value={program.id}>
                {program.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Activities combo */}
        <FormControl fullWidth={false} margin='normal' style={{ width: 400 }}>
          <InputLabel>Ishodna kognitivna aktivnost</InputLabel>
          <Select
            value={selectedCognitiveActivityId}
            onChange={(e) => setSelectedCognitiveActivityId(e.target.value)}
            label='Ishodna kognitivna aktivnost'
            disabled={!selectedProgramId}
          >
            {cognitiveActivities.map(activity => (
              <MenuItem key={activity.id} value={activity.id}>
                {activity.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Date selection */}
        <Box display='flex' flexDirection='row'>
          <FormControl fullWidth={false} margin='normal' style={{ width: 200 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* Date Picker for Date From */}
              <DatePicker
                label='Od'
                value={dateFrom}
                onChange={(date) => setDateFrom(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </FormControl>

          <FormControl fullWidth={false} margin='normal' style={{ width: 200 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {/* Date Picker for Date To */}
              <DatePicker
                label='Do'
                value={dateTo}
                onChange={(date) => setDateTo(date)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </FormControl>
        </Box>

      </Box>

      {/* Analytics selection */}
      <Typography variant='h6' gutterBottom style={{ marginTop: '20px' }}>
        Analitički postupci:
      </Typography>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={doRegressionTree}
              onChange={(e) => setDoRegressionTree(e.target.checked)}
              name='doRegressionTree'
            />
          }
          label='Regresijsko stablo'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={doRandomForest}
              onChange={(e) => setDoRandomForest(e.target.checked)}
              name='doRandomForest'
            />
          }
          label='Slučajna šuma'
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={doNeuralNetwork}
              onChange={(e) => setDoNeuralNetwork(e.target.checked)}
              name='doNeuralNetwork'
            />
          }
          label='Neuralna mreža'
        />
      </FormGroup>

      {/* Submit */}
      <Button
        onClick={onAnalyse}
        disabled={!selectedProgramId || !selectedCognitiveActivityId || loading}
        variant='contained'
        color='primary'
        style={{ marginTop: '20px' }}
      >
        Provedi analizu
      </Button>
    </Box>
  );
};

export default AnalysisManagement;
