export const compareUnordered = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false;
  }
  const elementCounts = new Map();

  for (const element of arr1) {
    elementCounts.set(element, (elementCounts.get(element) || 0) + 1);
  }

  for (const element of arr2) {
    const count = elementCounts.get(element);
    if (!count) {
      return false;
    }
    if (count === 1) {
      elementCounts.delete(element);
    } else {
      elementCounts.set(element, count - 1);
    }
  }

  return elementCounts.size === 0;
};
