export const googleActivityTypes = {
  // extracted from google fir react native library
  "Aerobics": "aerobics",
  "Archery": "archery",
  "Badminton": "badminton",
  "Baseball": "baseball",
  "Basketball": "basketball",
  "Biathlon": "biathlon",
  "Biking": "biking",
  "Handbiking": "biking.hand",
  "Mountain_biking": "biking.mountain",
  "Road_biking": "biking.road",
  "Spinning": "biking.spinning",
  "Stationary_biking": "biking.stationary",
  "Utility_biking": "biking.utility",
  "Boxing": "boxing",
  "Calisthenics": "calisthenics",
  "Circuit_training": "circuit_training",
  "Cricket": "cricket",
  "Crossfit": "crossfit",
  "Curling": "curling",
  "Dancing": "dancing",
  "Diving": "diving",
  "Elevator": "elevator",
  "Elliptical": "elliptical",
  "Ergometer": "ergometer",
  "Escalator": "escalator",
  "Fencing": "fencing",
  "Football_American": "football.american",
  "Football_Australian": "football.australian",
  "Football_Soccer": "football.soccer",
  "Frisbee_Disc": "frisbee_disc",
  "Gardening": "gardening",
  "Golf": "golf",
  "Guided_Breathing": "guided_breathing",
  "Gymnastics": "gymnastics",
  "Handball": "handball",
  "HIIT": "interval_training.high_intensity",
  "Hiking": "hiking",
  "Hockey": "hockey",
  "Horseback_riding": "horseback_riding",
  "Housework": "housework",
  "Ice_skating": "ice_skating",
  "In_vehicle": "in_vehicle",
  "Interval_Training": "interval_training",
  "Jumping_rope": "jump_rope",
  "Kayaking": "kayaking",
  "Kettlebell_training": "kettlebell_training",
  "Kickboxing": "kickboxing",
  "Kick_Scooter": "kick_scooter",
  "Kitesurfing": "kitesurfing",
  "Martial_arts": "martial_arts",
  "Meditation": "meditation",
  "Mime_Type_Prefix": "vnd.google.fitness.activity/",
  "Mixed_martial_arts": "martial_arts.mixed",
  "Other_unclassified_fitness_activity": "other",
  "P90X_exercises": "p90x",
  "Paragliding": "paragliding",
  "Pilates": "pilates",
  "Polo": "polo",
  "Racquetball": "racquetball",
  "Rock_climbing": "rock_climbing",
  "Rowing": "rowing",
  "Rowing_machine": "rowing.machine",
  "Rugby": "rugby",
  "Running": "running",
  "Jogging": "running.jogging",
  "Running_on_sand": "running.sand",
  "Running_treadmill": "running.treadmill",
  "Sailing": "sailing",
  "Scuba_diving": "scuba_diving",
  "Skateboarding": "skateboarding",
  "Skating": "skating",
  "Skating_Cross": "skating.cross",
  "Skating_Indoor": "skating.indoor",
  "Skating_Inline_rollerblading": "skating.inline",
  "Skiing": "skiing",
  "Skiing_Back_Country": "skiing.back_country",
  "Skiing_Cross_Country": "skiing.cross_country",
  "Skiing_Downhill": "skiing.downhill",
  "Skiing_Kite": "skiing.kite",
  "Skiing_Roller": "skiing.roller",
  "Sledding": "sledding",
  "Snowboarding": "snowboarding",
  "Snowmobile": "snowmobile",
  "Snowshoeing": "snowshoeing",
  "Softball": "softball",
  "Squash": "squash",
  "Stair_climbing": "stair_climbing",
  "Stair_climbing_machine": "stair_climbing.machine",
  "Stand_up_paddleboarding": "standup_paddleboarding",
  "Status_Active": "ActiveActionStatus",
  "Status_Completed": "CompletedActionStatus",
  "Still_not_moving": "still",
  "Strength_training": "strength_training",
  "Surfing": "surfing",
  "Swimming": "swimming",
  "Swimming_open_water": "swimming.open_water",
  "Swimming_swimming_pool": "swimming.pool",
  "Table_tennis_ping_pong": "table_tennis",
  "Team_sports": "team_sports",
  "Tennis": "tennis",
  "Tilting_sudden_device_gravity_change": "tilting",
  "Treadmill_walking_or_running": "treadmill",
  "Unknown_unable_to_detect_activity": "unknown",
  "Volleyball": "volleyball",
  "Volleyball_beach": "volleyball.beach",
  "Volleyball_indoor": "volleyball.indoor",
  "Wakeboarding": "wakeboarding",
  "Walking": "walking",
  "Walking_fitness": "walking.fitness",
  "Walking_nording": "walking.nordic",
  "Walking_treadmill": "walking.treadmill",
  "Walking_stroller": "walking.stroller",
  "Waterpolo": "water_polo",
  "Weightlifting": "weightlifting",
  "Wheelchair": "wheelchair",
  "Windsurfing": "windsurfing",
  "Yoga": "yoga",
  "Zumba": "zumba"
}

const googleExerciseTypes = {
  // WIP / WTF from: https://developers.google.com/fit/datatypes/activity ... where is this used ?
  // back_extension
  // bridge.hip.single_leg
  // burpee
  // calf_press
  // calf_raise
  // calf_raise.seated
  // calf_raise.standing
  // chinup
  // clean
  // clean.hang
  // clean.hang_power
  // clean.jerk
  // clean.power
  // crunch
  // crunch.twisting
  // curl.bicep
  // deadlift
  // deadlift.rdl
  // deadlift.single_leg
  // deadlift.straight_leg
  // dip
  // dip.chest
  // dip.triceps
  // fly
  // good_morning
  // hip_extension
  // hip_raise
  // hip_thrust
  // jumping_jack
  // leg_curl
  // leg_extension
  // leg_press
  // leg_raise
  // lunge
  // lunge.rear
  // lunge.side
  // plank
  // plank.side
  // press.bench
  // press.bench.close_grip
  // press.bench.decline
  // press.bench.incline
  // press.jm
  // press.pike
  // press.shoulder
  // press.shoulder.arnold
  // press.shoulder.military
  // pulldown
  // pullover
  // pullup
  // pushup
  // pushup.close_grip
  // pushup.pike
  // raise.front
  // raise.lateral
  // raise.lateral.rear
  // row
  // row.high
  // row.upright
  // run.high_knee
  // russian_twist
  // shrug
  // situp
  // situp.twisting
  // squat
  // step_up
  // swing
  // thruster
  // triceps_extension
  // vups
  // wall_sit
}

/// from react-native-health
export const appleHealthActivityTypes = {
  "AmericanFootball": 'AmericanFootball',
  "Archery": 'Archery',
  "AustralianFootball": 'AustralianFootball',
  "Badminton": 'Badminton',
  "Baseball": 'Baseball',
  "Basketball": 'Basketball',
  "Bowling": 'Bowling',
  "Boxing": 'Boxing',
  "CardioDance": 'CardioDance',
  "Climbing": 'Climbing',
  "Cooldown": 'Cooldown',
  "Cricket": 'Cricket',
  "CrossTraining": 'CrossTraining',
  "Curling": 'Curling',
  "Cycling": 'Cycling',
  "Dance": 'Dance',
  "DiscSports": 'DiscSports',
  "Elliptical": 'Elliptical',
  "EquestrianSports": 'EquestrianSports',
  "Fencing": 'Fencing',
  "FitnessGaming": 'FitnessGaming',
  "Fishing": 'Fishing',
  "FunctionalStrengthTraining": 'FunctionalStrengthTraining',
  "Golf": 'Golf',
  "Gymnastics": 'Gymnastics',
  "Handball": 'Handball',
  "Hiking": 'Hiking',
  "Hockey": 'Hockey',
  "Hunting": 'Hunting',
  "Lacrosse": 'Lacrosse',
  "MartialArts": 'MartialArts',
  "MindAndBody": 'MindAndBody',
  "PaddleSports": 'PaddleSports',
  "Play": 'Play',
  "Pickleball": 'Pickleball',
  "PreparationAndRecovery": 'PreparationAndRecovery',
  "Racquetball": 'Racquetball',
  "Rowing": 'Rowing',
  "Rugby": 'Rugby',
  "Running": 'Running',
  "Sailing": 'Sailing',
  "SkatingSports": 'SkatingSports',
  "SnowSports": 'SnowSports',
  "Soccer": 'Soccer',
  "SocialDance": 'SocialDance',
  "Softball": 'Softball',
  "Squash": 'Squash',
  "StairClimbing": 'StairClimbing',
  "SurfingSports": 'SurfingSports',
  "Swimming": 'Swimming',
  "TableTennis": 'TableTennis',
  "Tennis": 'Tennis',
  "TrackAndField": 'TrackAndField',
  "TraditionalStrengthTraining": 'TraditionalStrengthTraining',
  "Volleyball": 'Volleyball',
  "Walking": 'Walking',
  "WaterFitness": 'WaterFitness',
  "WaterPolo": 'WaterPolo',
  "WaterSports": 'WaterSports',
  "Wrestling": 'Wrestling',
  "Yoga": 'Yoga',
  "Barre": 'Barre',
  "CoreTraining": 'CoreTraining',
  "CrossCountrySkiing": 'CrossCountrySkiing',
  "DownhillSkiing": 'DownhillSkiing',
  "Flexibility": 'Flexibility',
  "HighIntensityIntervalTraining": 'HighIntensityIntervalTraining',
  "JumpRope": 'JumpRope',
  "Kickboxing": 'Kickboxing',
  "Pilates": 'Pilates',
  "Snowboarding": 'Snowboarding',
  "Stairs": 'Stairs',
  "StepTraining": 'StepTraining',
  "WheelchairWalkPace": 'WheelchairWalkPace',
  "WheelchairRunPace": 'WheelchairRunPace',
  "TaiChi": 'TaiChi',
  "MixedCardio": 'MixedCardio',
  "HandCycling": 'HandCycling'
}
