// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import AppConfig from '../AppConfig';
import {userLogout} from '../store/auth';
import Cookies from 'js-cookie';
import store from './../store';
import {toast} from "react-toastify";


export async function client(endpoint, {body, ...customConfig} = {}) {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  };

  endpoint = endpoint.replace(/^\/+/, '').replace(/\/$/, ''); // trim slashes

  const csrf_token = Cookies.get('csrf_access_token');
  if (csrf_token) {
    headers['X-CSRF-TOKEN'] = csrf_token;
  }

  const config = {
    method: body ? 'POST' : 'GET',
    mode: process.env.NODE_ENV === 'development' ? 'cors' : 'same-origin',
    credentials: 'include',
    body: body ? typeof body === 'string' ? body : JSON.stringify(body) : undefined,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  try {
    const result = await fetch(AppConfig.backendUrl + '/' + endpoint, config);

    // downloads are recognised via 'Content-Disposition' starting with "attachment"
    // backend is required to make it so
    const disposition = result.headers.get('Content-Disposition');
    if (disposition?.startsWith('attachment')) {
      let filename = 'download';

      const filenameMatch = disposition.match(/.*filename=(.+)/);
      if (filenameMatch) {
        filename = filenameMatch[1];
      }

      const blob = await result.blob();
      return { blob, filename };
    }

    // otherwise presuppose it's a json response
    const response = await result.json();
    if (response.message) {
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    }

    if (result.ok) {
      return response;
    }

    if (result.status === 401 && store.getState().auth.user?.id) {
      store.dispatch(userLogout());
    }

    throw new Error(response?.message || result.statusText);
  } catch (err) {
    return Promise.reject(err);
  }
}

client.get = async function (endpoint, customConfig = {}) {
  return client(endpoint, {...customConfig, method: 'GET'});
};

client.post = async function (endpoint, body, customConfig = {}) {
  return client(endpoint, {...customConfig, body});
};

export const defaultOnFulfilled = (state, action) => {
  let name_split = action.type.split("/");
  state[name_split[1] + 'Loading'] = false;
};
export const defaultOnRejected = (state, action) => {
  let name_split = action.type.split("/");
  state[name_split[1] + 'Loading'] = false;
  state.error = action.payload;
};
export const defaultOnPending = (state, action) => {
  let name_split = action.type.split("/");
  state[name_split[1] + 'Loading'] = true;
  state.error = null;
};
