import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {client} from '../util/apiClient';

const createBaseRemoteStore = (entityName, options) => {
  if(!options || !entityName) {
    throw new Error('Base store factory "entityName" and "options" must be provided!');
  }

  const initialState = {
    listData: options.initalListData || [],
    getData: options.initalGetData || [],
    listLoading: false,
    getLoading: false,
    addLoading: false,
    updateLoading: false,
    deleteLoading: false,
  };
  const listData = createAsyncThunk(`${entityName}/list`, async () => {
    const response = await client.get(options.listUrl || `${entityName}/list`);
    return response.data;
  });
  const getData = createAsyncThunk(`${entityName}/get`, async (
    {...fields}, {rejectWithValue}
  ) => {
    try {
      const data = await client.post(options.getUrl || `${entityName}/get`, fields);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  const addData = createAsyncThunk(`${entityName}/add`, async (
    {...fields}, {rejectWithValue}
  ) => {
    try {
      const data = await client.post(options.addUrl || `${entityName}/add`, fields);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  const updateData = createAsyncThunk(`${entityName}/update`, async (
    {...fields}, {rejectWithValue}
  ) => {
    try {
      const data = await client.post(options.updateUrl || `${entityName}/update`, fields);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });
  const deleteData = createAsyncThunk(`${entityName}/delete`, async (
    {...fields}, {rejectWithValue}
  ) => {
    try {
      const data = await client.post(options.deleteUrl || `${entityName}/delete`, fields);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  });


  const slice = createSlice({
    name: entityName,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(listData.pending, (state, action) => {
          state.listLoading = true;
        })
        .addCase(listData.fulfilled, (state, action) => {
          state.listData = action.payload;
          state.listLoading = false;
        })
        .addCase(listData.rejected, (state, action) => {
          state.listData = [];
          state.listLoading = false;
        })
        .addCase(getData.pending, (state, action) => {
          state.getLoading = true;
        })
        .addCase(getData.fulfilled, (state, action) => {
          state.getData = action.payload;
          state.getLoading = false;
        })
        .addCase(getData.rejected, (state, action) => {
          state.getData = [];
          state.getLoading = false;
        })
        .addCase(addData.pending, (state, action) => {
          state.addLoading = true;
        })
        .addCase(addData.fulfilled, (state, action) => {
          state.addData = action.payload;
          state.addLoading = false;
        })
        .addCase(addData.rejected, (state, action) => {
          state.addData = [];
          state.addLoading = false;
        })
        .addCase(updateData.pending, (state, action) => {
          state.updateLoading = true;
        })
        .addCase(updateData.fulfilled, (state, action) => {
          state.updateData = action.payload;
          state.updateLoading = false;
        })
        .addCase(updateData.rejected, (state, action) => {
          state.updateData = [];
          state.updateLoading = false;
        })
        .addCase(deleteData.pending, (state, action) => {
          state.deleteLoading = true;
        })
        .addCase(deleteData.fulfilled, (state, action) => {
          state.deleteData = action.payload;
          state.deleteLoading = false;
        })
        .addCase(deleteData.rejected, (state, action) => {
          state.deleteData = [];
          state.deleteLoading = false;
        })
    },
  });

  return {
    reducer: slice.reducer,
    listData,
    addData,
    getData,
    updateData,
    deleteData,
  };
};

export default createBaseRemoteStore;
