import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {client, defaultOnFulfilled, defaultOnPending, defaultOnRejected} from '../util/apiClient';
import Cookies from 'js-cookie';


const initialState = {
  loading: false,
  authLoading: false,
  validateEmailLoading: false,
  user: {}, // for user object
  permissionNames: {
    userManagement: 'userManagement',
    userDelete: 'userDelete',
    activityManagement: 'activityManagement',
    programManagement: 'programManagement',
  },
  error: ''
};


export const userLogin = createAsyncThunk(
  'auth/login',
  async ({email, password}, {rejectWithValue}) => {
    try {
      const data = await client.post('/auth/login', {email, password});
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const sendOneTimePassword = createAsyncThunk(
  'auth/oneTimePassword',
  async ({email}, {rejectWithValue}) => {
    try {
      const data = await client.post('/auth/one_time_password', {email});
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const validateEmail = createAsyncThunk(
  'auth/validateEmail',
  async (token, {rejectWithValue}) => {
    try {
      const data = await client.get('/auth/confirm/' + token);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const loginWithCookies = createAsyncThunk(
  'auth/loginWithCookies',
  async (a, {rejectWithValue}) => {
    try {
      const data = await client.get('/auth/login');
      return data;
    } catch (error) {
      Cookies.remove('csrf_access_token');
      return rejectWithValue(error.message);
    }
  }
);
loginWithCookies.onFulfilled = (state, action) => {
  state.authLoading = false;
  state.user = action.payload.user;
};
loginWithCookies.onRejected = (state, action) => {
  state.authLoading = false;
  state.error = action.payload;
  state.user = {};
};
loginWithCookies.onPending = (state) => {
  state.authLoading = true;
  state.error = null;
};


export const userLogout = createAsyncThunk(
  'auth/logout',
  async () => {
    const data = await client.post('/auth/logout');
    return data;
  }
);


export const userRegister = createAsyncThunk(
  'auth/register',
  async ({...fields}, {rejectWithValue}) => {
    try {
      const data = await client.post('/auth/register', fields);
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.authLoading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.authLoading = false;
        state.user = action.payload.user;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.authLoading = false;
        state.error = action.payload;
      })
      .addCase(userLogout.pending, (state) => {
        state.authLoading = true;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.authLoading = initialState.authLoading;
        state.user = initialState.user;
        state.error = initialState.error;
      })
      .addCase(userLogout.rejected, (state) => {
        //if API call fails still logout frontend
        state.authLoading = initialState.authLoading;
        state.user = initialState.user;
        state.error = initialState.error;
      })
      .addCase(validateEmail.fulfilled, defaultOnFulfilled)
      .addCase(validateEmail.rejected, defaultOnRejected)
      .addCase(validateEmail.pending, defaultOnPending)
      .addCase(loginWithCookies.fulfilled, loginWithCookies.onFulfilled)
      .addCase(loginWithCookies.rejected, loginWithCookies.onRejected)
      .addCase(loginWithCookies.pending, loginWithCookies.onPending)
  }
});

export default auth.reducer;
