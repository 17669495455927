import React, {useCallback, useEffect, useRef, useState} from 'react';
import {
  Box,
  Button, ListItemIcon, MenuItem, Paper,
  Stack,
  TextField
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {useForm} from "react-hook-form";
import {useDispatch, useStore} from "react-redux";
import {addProgram, getProgram, updateProgram} from "../../../store/programs";
import {listActivities} from "../../../store/activities";
import {listUsers} from "../../../store/users";
import {Check} from "@mui/icons-material";


const ProgramAddUpdateView = ({programEdit = false, programView = false, ...props}) => {
  const store = useStore();
  const defaultValues = {
    name: '',
    description: '',
    activities: [],
    users: [],
    owners: [],
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {register, handleSubmit, reset, watch, setValue} = useForm({
    defaultValues: defaultValues,
  });

  const {id} = useParams();
  const [activities, setActivities] = React.useState([])
  const [users, setUsers] = React.useState([])

  const resetForm = useCallback((data) => {
    reset({
      id: data.id,
      name: data?.name ? data.name : defaultValues.name,
      description: data?.description ? data.description : defaultValues.description,
      users: data?.users ? data.users.map(u => u.user_id) : defaultValues.users,
      owners: data?.owners ? data.owners : defaultValues.owners,
      activities: data?.activities ? data.activities.map(a => a.activity_id) : defaultValues.activities,
    })
  }, [])

  useEffect(() => {
    dispatch(listActivities()).then(data => setActivities(data.payload));
    dispatch(listUsers()).then(data => setUsers(data.payload));
    if (id && !store.getState().activity?.getLoading) {
      dispatch(getProgram({id: id})).then(res => {
        resetForm(res.payload.data)
      });
    }
  }, [id, programEdit]);

  const onSave = useCallback((data) => {
    if (id || programEdit) {
      dispatch(updateProgram(data));
    } else {
      dispatch(addProgram(data));
    }
  }, [dispatch]);

  return <Box height={800}>
    <Stack direction='row'>
      <Button onClick={() => navigate(-1)}>Nazad</Button>
    </Stack>
    <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
      <Grid
        container
        spacing={0}
        style={{minHeight: '50vh'}}
      >
        <Grid item xs={12}>
          <form className="form" onSubmit={handleSubmit(onSave)}>
            <Grid item margin={1} xs={6}>
              <TextField
                fullWidth
                required
                autoComplete='no'
                label="Naziv"
                disabled={programView}
                InputLabelProps={{ shrink: !!watch('name') }}
                {...register('name', {required: true})}
              />
            </Grid>
            <Grid item margin={1} xs={6}>
              <TextField
                fullWidth
                required
                autoComplete='no'
                label="Opis"
                disabled={programView}
                InputLabelProps={{ shrink: !!watch('description') }}
                {...register('description', {required: true})}
              />
            </Grid>
            <Grid item margin={1} xs={6}>
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  value: watch('owners'),
                }}
                fullWidth
                label="Vlasnici"
                disabled={programView}
                defaultValue={[]}
                {...register('owners')}
              >
                {
                  users.map((data) => (
                    <MenuItem key={String(data.id)} value={String(data.id)}> {String(data.name)} {String(data.surname)} </MenuItem>
                  ))
                }
              </TextField>
            </Grid>
            <Grid item margin={1} xs={6}>
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  value: watch('activities'),
                }}
                fullWidth
                label="Aktivnosti programa"
                disabled={programView}
                defaultValue={[]}
                {...register('activities')}
              >
                {
                  activities.map((data) => (
                    <MenuItem key={String(data.id)} value={String(data.id)}>
                      {String(data.name)}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Grid>
            <Grid item margin={1} xs={6}>
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  value: watch('users'),
                }}
                fullWidth
                label="Korisnici programa"
                disabled={programView}
                defaultValue={[]}
                {...register('users')}
              >
                {
                  users.map((data) => (
                    <MenuItem key={String(data.id)} value={String(data.id)}> {String(data.name)} {String(data.surname)} </MenuItem>
                  ))
                }
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row-reverse">
                {!programView ? <Button type="submit" color="primary">{id ? 'Ažuriraj' : 'Spremi'}</Button> : null}
              </Stack>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  </Box>;
};

export default ProgramAddUpdateView;
