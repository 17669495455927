import React, {useEffect} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tabs,
  useMediaQuery, useTheme
} from '@mui/material';
import PropTypes from 'prop-types';

const AdvanceDialog = props => {
  const {
    openDialog,
    setOpenDialog,
    title,
    description,
    cancelText,
    confirmText,
    onCancel,
    onConfirm,
    ...otherProps
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={openDialog}
      onClose={() => {setOpenDialog(false)}}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => {onCancel(); setOpenDialog(false);}}>
          {cancelText}
        </Button>
        <Button onClick={() => {onConfirm(); setOpenDialog(false);}} autoFocus>
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AdvanceDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
};

AdvanceDialog.defaultProps = {
  title: 'Jeste li sigurni?',
  cancelText: 'Odustani',
  confirmText: 'Potvrdi',
  openDialog: false,
  setOpenDialog: () => {},
  onConfirm: () => {},
  onCancel: () => {},
};

export default AdvanceDialog;
