import createBaseStore from './baseStoreFactory';

const store = createBaseStore('program', {
  listUrl: '/program/list',
  getUrl: '/program/get',
  addUrl: '/program/add',
  updateUrl: '/program/update',
  deleteUrl: '/program/delete',
});

export default store.reducer;
export const listPrograms = store.listData;
export const getProgram = store.getData;
export const addProgram = store.addData;
export const deleteProgram = store.deleteData;
export const updateProgram = store.updateData;
