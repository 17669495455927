import * as React from 'react';
import {useCallback} from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from "@mui/material/Grid";
import {Button, Stack, TextField} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {userRegister} from '../../store/auth.js';

const RegisterContent = () => {
  const navigate = useNavigate();
  const {register, handleSubmit, watch} = useForm();
  const dispatch = useDispatch();

  const onRegister = useCallback((data) => {
    dispatch(userRegister(data));
  }, [dispatch]);

  return (
    <Box>
      <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{minHeight: '50vh'}}
        >
          <Grid item xs={12}>
            <h2 style={{textAlign: 'center'}}>Registracija</h2>
            <form className="form" onSubmit={handleSubmit(onRegister)}>
              <Grid item margin={1} xs={12}>
                <TextField
                  required
                  label="Ime"
                  {...register('name', {required: true})}
                />
              </Grid>
              <Grid item margin={1} xs={12}>
                <TextField
                  required
                  label="Prezime"
                  {...register('surname', {required: true})}
                />
              </Grid>
              <Grid item margin={1} xs={12}>
                <TextField
                  required
                  label="E-mail"
                  type={'email'}
                  {...register('email', {required: true})}
                />
              </Grid>
              <Grid item margin={1} xs={12}>
                <TextField
                  required
                  label="Lozinka"
                  type="password"
                  {...register('password', {required: true})}
                />
              </Grid>
              <Grid item margin={1} xs={12}>
                <TextField
                  required
                  label="Potvrda lozinke"
                  type="password"
                  {...register('password_confirm', {
                    required: true,
                    validate: (val) => {
                      if (watch('password') !== val) {
                        return "Neispravna potvrda lozinke";
                      }
                    },
                  })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  spacing={5} direction="row"
                >
                  <Button onClick={() => navigate('/login')} color="warning">Nazad</Button>
                  <Button type="submit" color="primary">Registracija</Button>
                </Stack>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default RegisterContent;
