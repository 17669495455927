import createBaseStore from './baseStoreFactory';
import {createAsyncThunk} from "@reduxjs/toolkit";
import {client} from "../util/apiClient";

const store = createBaseStore('user', {
  listUrl: '/user/list',
  getUrl: '/user/get',
  addUrl: '/user/add',
  updateUrl: '/user/update',
  deleteUrl: '/user/delete',
});

export default store.reducer;
export const listUsers = store.listData;
export const getUser = store.getData;
export const addUser = store.addData;
export const deleteUser = store.deleteData;
export const updateUser = store.updateData;

export const forceLogoutUser = createAsyncThunk(
  'user/forceLogoutUser',
  async ({id}, {rejectWithValue}) => {
    try {
      const data = await client.post('/user/force/logout', {id});
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
