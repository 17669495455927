import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Box,
  Button, Chip, MenuItem, Paper,
  Stack,
  TextField
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {useForm, Controller} from "react-hook-form";
import {useDispatch, useStore} from "react-redux";
import {addActivity, getActivity, getActivityTypes, listActivities, updateActivity} from "../../../store/activities";
import Autocomplete from '@mui/material/Autocomplete';
import { googleActivityTypes, appleHealthActivityTypes } from '../../../assets/platformTypes';


const run_schema_example = {
  $schema: "primer_activity.json",
  $id: "primer_activity.json",
  title: "Unos primjer aktivnosti",
  type: "object",
  properties: {
    base_info: {
      title: "Opće informacije",
      type: "object",
      properties: {
        difficulty: {
          title: "Procjena težine",
          type: "number",
          minimum: 0,
          maximum: 5
        },
        psychical_skills: {
          title: "Vještine",
          description: "Uključene fizičke vještine (npr. kordinacija, snaga, okretnost, ravnoteža ...)",
          type: "array",
          items: {
            type: "string",
            title: "Vještina"
          },
          minItems: 1,
          uniqueItems: true
        }
      }
    }
  },
  required: [
    "base_info"
  ]
}

const meta_data_example = {
  skills: ["coordination", "strength", "agility", "balance"],
  difficulty: 3,
  description: "This is a special extra description of the activity",
}

const ActivityAddUpdateView = ({activityEdit = false, activityView = false, ...props}) => {
  const store = useStore();
  const defaultValues = {
    type: '',
    name: '',
    description: '',
    meta_data: {},
    run_schema: {},
    meta_data_string: '{}',
    run_schema_string: '{}',
    apple_identifiers: [],
    google_identifiers: [],
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {register, handleSubmit, reset, watch, setValue, control} = useForm({
    defaultValues: defaultValues,
  });


  const {id} = useParams();
  const [activityTypes, setActivityTypes] = React.useState({})

  const handleTabInTextfield = useCallback(e => {
      const { value } = e.target;
      if (e.key === 'Tab') {
        e.preventDefault();

        const cursorPosition = e.target.selectionStart;
        const cursorEndPosition = e.target.selectionEnd;
        const tab = '\t';

        e.target.value =
          value.substring(0, cursorPosition) +
          tab +
          value.substring(cursorEndPosition);

        // if you modify the value programmatically, the cursor is moved
        // to the end of the value, we need to reset it to the correct
        // position again
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
      }
  })


  const resetForm = useCallback((data) => {
    reset({
    })
  }, [])

  useEffect(() => {
    dispatch(getActivityTypes({})).then(data => setActivityTypes(data.payload.data));
    if (id && !store.getState().activity?.getLoading) {
      dispatch(getActivity({id: id})).then(res => {
        setValue('id', res.payload.data.id);
        setValue('type', res.payload.data.type);
        setValue('name', res.payload.data.name);
        setValue('description', res.payload.data.description);
        setValue('meta_data_string', JSON.stringify(res.payload.data.meta_data, undefined, 2));
        setValue('run_schema_string', JSON.stringify(res.payload.data.run_schema, undefined, 2));
        setValue('apple_identifiers', res.payload.data.apple_identifiers || []);
        setValue('google_identifiers', res.payload.data.google_identifiers || []);
      });
    }
  }, [id, activityEdit]);

  const onSave = useCallback((data) => {
    data.meta_data = JSON.parse(data.meta_data_string);
    data.run_schema = JSON.parse(data.run_schema_string);
    delete data.run_schema_string
    delete data.meta_data_string
    if (id || activityEdit) {
      dispatch(updateActivity(data));
    } else {
      dispatch(addActivity(data));
    }
  }, [dispatch]);

  const googleActivityTypesArr = useMemo(() => {
    return Object.entries(googleActivityTypes).map(([key, value]) => value)
  }, []);

  const appleHealthActivityTypesArr = useMemo(() => {
    return Object.entries(appleHealthActivityTypes).map(([key, value]) => value)
  }, []);



  return <Box height={800}>
    <Stack direction='row'>
      <Button onClick={() => navigate(-1)}>Nazad</Button>
    </Stack>
    <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
      <Grid
        container
        spacing={0}
        style={{minHeight: '50vh'}}
      >
        <Grid item xs={12}>
          <form className="form" onSubmit={handleSubmit(onSave)}>
            <Grid item margin={1} xs={6}>
              <TextField
                select
                SelectProps={{
                  value: watch('type'),
                }}
                fullWidth
                label="Vrsta aktivnosti"
                disabled={activityView}
                defaultValue={[]}
                {...register('type')}
              >
                {
                  Object.entries(activityTypes).map(([key, value], id) => (
                      <MenuItem key={String(key)} value={String(key)}>
                        {String(value)}
                      </MenuItem>
                    )
                  )
                }
              </TextField>
            </Grid>
            <Grid item margin={1} xs={6}>
              <TextField
                fullWidth
                required
                autoComplete='no'
                label="Naziv"
                disabled={activityView}
                InputLabelProps={{ shrink: !!watch('name') }}
                {...register('name', {required: true})}
              />
            </Grid>
            <Grid item margin={1} xs={6}>
              <TextField
                fullWidth
                required
                autoComplete='no'
                label="Opis"
                disabled={activityView}
                InputLabelProps={{ shrink: !!watch('description') }}
                {...register('description', {required: true})}
              />
            </Grid>
            <Grid item margin={1} xs={6}>
              <Controller
                control={control}
                name="google_identifiers"
                render={({field}) =>
                <Autocomplete
                  {...field}
                  multiple
                  options={googleActivityTypesArr}
                  freeSolo
                  disabled={activityView}
                  onChange={(event, value) => {field.onChange([...new Set(value.sort())])}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Google Fit identifikatori"
                    />
                  )}
                />
                }
              />
            </Grid>
            <Grid item margin={1} xs={6}>
            <Controller
                control={control}
                name="apple_identifiers"
                render={({field}) =>
                <Autocomplete
                  {...field}
                  multiple
                  options={appleHealthActivityTypesArr}
                  freeSolo
                  disabled={activityView}
                  onChange={(event, value) => {field.onChange([...new Set(value.sort())])}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Apple HealthKit identifikatori"
                    />
                  )}
                />
                }
              />
            </Grid>
            <Grid container spacing={1} xs={12}>
              <Grid item xs={6}>
                <TextField
                  sx={{margin: 1}}
                  multiline
                  fullWidth
                  required
                  autoComplete='no'
                  label="Definicija potrebnih polja za unos aktivnosti"
                  rows={20}
                  disabled={activityView}
                  InputLabelProps={{ shrink: !!watch('run_schema_string') }}
                  onKeyDown={(e) => handleTabInTextfield(e)}
                  {...register('run_schema_string')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{margin: 1}}
                  multiline
                  fullWidth
                  label="Primjer definicije potrebnih polja za unos aktivnosti"
                  value={JSON.stringify(run_schema_example, undefined, 2)}
                  rows={20}
                  disabled={true}
                  InputLabelProps={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} xs={12}>
              <Grid item xs={6}>
                <TextField
                  sx={{margin: 1}}
                  multiline
                  fullWidth
                  required
                  autoComplete='no'
                  label="Definicija dodatnih podataka o aktivnosti"
                  rows={20}
                  disabled={activityView}
                  InputLabelProps={{ shrink: !!watch('meta_data_string') }}
                  onKeyDown={(e) => handleTabInTextfield(e)}
                  {...register('meta_data_string')}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  sx={{margin: 1}}
                  multiline
                  fullWidth
                  label="Primjer definicije dodatnih informacija za aktivnosti"
                  value={JSON.stringify(meta_data_example, undefined, 2)}
                  rows={20}
                  disabled={true}
                  InputLabelProps={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Stack direction="row-reverse">
                {!activityView ? <Button type="submit" color="primary">{id ? 'Ažuriraj' : 'Spremi'}</Button> : null}
              </Stack>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  </Box>;
};

export default ActivityAddUpdateView;
