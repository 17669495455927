import {configureStore, combineReducers} from '@reduxjs/toolkit';
import authReducer from './auth.js';
import settingsUsersReducer from './users.js';
import settingsActivitiesReducer from './activities';
import settingsProgramsReducer from './programs';
import activityRunsReducer from './activities_runs';


const allReducers = combineReducers({
  auth: authReducer,
  settingsUsers: settingsUsersReducer,
  settingsActivities: settingsActivitiesReducer,
  settingsPrograms: settingsProgramsReducer,
  activityRuns: activityRunsReducer,
});

const rootReducer = (state, action) => {
  // if (action.type == 'login')
  return allReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
