import {React} from 'react';
import {Box, Button, Stack} from '@mui/material';
import {listPrograms, deleteProgram} from "../../../store/programs";
import {useNavigate} from "react-router-dom";
import EntityDataGrid from "../../common/EntityDataGrid";


const columns =  [
  {
    field: 'name',
    headerName: 'Naziv',
    width: 150,
    editable: false,
  },
  {
    field: 'description',
    headerName: 'Opis',
    width: 400,
    editable: false,
  },
];

const ProgramManagement = props => {
  const navigate = useNavigate();

  return <Box height={800}>
    <Stack direction='row-reverse'>
      <Button onClick={() => navigate('/settings/programs/add')}>Dodaj program</Button>
    </Stack>
    <EntityDataGrid
      columns={columns}
      fetchActions={listPrograms}
      dataSelector={(state) => state.settingsPrograms.listData}
      statusSelector={(state) => state.settingsPrograms.listLoading}
      deleteActions={deleteProgram}
      deleteDialogConfig={{
        getDeleteDescription: (row) => (`Jeste li sigurni da želite izbrisati program - ${row?.row?.name}`)
      }}
      deleteColumn={true}
      viewColumn={true}
      updateColumn={true}
    />

  </Box>;
};

export default ProgramManagement;
