import React, { useCallback, useEffect, useState } from 'react';
import { Box, Tab } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TabContext, TabPanel } from '@mui/lab';
import AdvanceTabs from "../components/common/AdvanceTabs";
import UsersManagement from "../components/settings/users/UsersManagement";
import {useSelector} from "react-redux";
import UserAddUpdate from "../components/settings/users/UserAddUpdate";
import ActivityManagement from "../components/settings/activities/ActivityManagement";
import ProgramManagement from "../components/settings/programs/ProgramManagement";
import AnalysisManagement from "../components/settings/analytics/AnalysisManagement";

const Settings = () => {

  const [tabValues, setTabValues] = useState([]);
  const {section} = useParams();
  const navigate = useNavigate();
  const currentPath = useLocation();

  const authUser = useSelector((state) => state.auth.user)
  const permissionNames = useSelector((state) => state.auth.permissionNames)
  const handleChange = useCallback((event, newValue) => {
    if(!section) {
      navigate(currentPath.pathname + newValue);
    } else {
      const parentPath = currentPath.pathname.replace(/^(.*\/)[^/]+$/, '$1');
      navigate(`${parentPath}${newValue}`);
    }
  }, [currentPath.pathname, navigate, section]);
  useEffect(() => {
    const default_tab = authUser?.permissions?.includes(permissionNames.userManagement) ? 'users' : 'profile'
    if(!section) {
      navigate(`${currentPath.pathname}${default_tab}`);
    } else {
      const parentPath = currentPath.pathname.replace(/^(.*\/)[^/]+$/, '$1');
      if(!tabValues.includes(section)) {
        navigate(`${parentPath}${default_tab}`);
      }
    }
  }, [section, navigate, currentPath.pathname, tabValues]);
  return <Box>
    <h1>Postavke</h1>
    <Box>
      <TabContext value={section}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <AdvanceTabs
            onTabValuesChange={setTabValues}
            onChange={handleChange}
            value={section}
            textColor="secondary"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {
              authUser?.permissions?.includes(permissionNames.userManagement) ?
                <Tab label="Korisnici" value='users'/> : <Tab label="Profil" value='profile'/>
            }
            {authUser?.permissions?.includes(permissionNames.activityManagement) ? <Tab label="Aktivnosti" value='activities'/> : null}
            {authUser?.permissions?.includes(permissionNames.programManagement) ? <Tab label="Programi" value='programs'/> : null}
            {authUser?.permissions?.includes(permissionNames.programManagement) ? <Tab label="Izvještaji i analiza" value='analysis'/> : null}
          </AdvanceTabs>
        </Box>
        {
          authUser?.permissions?.includes(permissionNames.userManagement) ?
            <TabPanel value='users' sx={{p: 1}}> <UsersManagement /> </TabPanel> :
            <TabPanel value='profile' sx={{p: 1}}> <UserAddUpdate profileEdit={true} goBack={false} /> </TabPanel>
        }
        {authUser?.permissions?.includes(permissionNames.activityManagement) ?
          <TabPanel value='activities' sx={{p: 1}}> <ActivityManagement /> </TabPanel> : null }
        {authUser?.permissions?.includes(permissionNames.programManagement) ?
          <TabPanel value='programs' sx={{p: 1}}> <ProgramManagement /> </TabPanel> : null }
        {authUser?.permissions?.includes(permissionNames.programManagement) ?
          <TabPanel value='analysis' sx={{p: 1}}> <AnalysisManagement /> </TabPanel> : null }
      </TabContext>
    </Box>

  </Box>;
};

export default Settings;
