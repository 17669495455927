import createBaseStore from './baseStoreFactory';
import {createAsyncThunk} from "@reduxjs/toolkit";
import {client} from "../util/apiClient";

const store = createBaseStore('activity', {
  listUrl: '/activity/list',
  getUrl: '/activity/get',
  addUrl: '/activity/add',
  updateUrl: '/activity/update',
  deleteUrl: '/activity/delete',
});

export default store.reducer;
export const listActivities = store.listData;
export const getActivity = store.getData;
export const addActivity = store.addData;
export const deleteActivity = store.deleteData;
export const updateActivity = store.updateData;

export const getActivityTypes = createAsyncThunk(
  'activitiy/getActivityTypes',
  async (
    {...fields}, {rejectWithValue}
  ) => {
    try {
      const data = await client.get('/activity/types' );
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
