import React, {useCallback, useEffect} from 'react';
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel, MenuItem,
  OutlinedInput, Select,
  Stack,
  TextField
} from '@mui/material';
import {useNavigate, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import {useForm} from "react-hook-form";
import PasswordIcon from '@mui/icons-material/Password';
import {useDispatch, useSelector, useStore} from "react-redux";
import {getUser, addUser, updateUser, forceLogoutUser} from "../../../store/users";
import AdvanceDialog from "../../common/AdvanceDialog";
import { v4 as uuid } from 'uuid';


const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/;
const IGNORE_KEYS_LIST_PSW_HIDE = ['control', 'c', 'backspace'];

const UserAddUpdate = ({profileEdit = false, goBack= true, ...props}) => {
  const store = useStore();
  const defaultValues = {
    name: '',
    surname: '',
    email: '',
    password: '',
    permissions: [],
    api_key: '',
  }
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {register, handleSubmit, reset, watch, setValue} = useForm({
    defaultValues: defaultValues,
  });
  const {id} = useParams();
  const authUser = useSelector((state) => state.auth.user);
  const permissionNames = useSelector((state) => state.auth.permissionNames);
  const generatePassword = useCallback(() => {
    let psw = Math.random().toString(36).slice(-8).toUpperCase();
    setValue('password', psw)
    setHidePassword(false)
  }, []);

  const [forceLogoutUserDialog, setForceLogoutUserDialog] = React.useState(false)
  const [hidePassword, setHidePassword] = React.useState(true)

  const resetForm = useCallback((data) => {
    reset({
      name: data?.name ? data.name : defaultValues.name,
      surname: data?.surname ? data.surname : defaultValues.surname,
      email: data?.email ? data.email : defaultValues.email,
      permissions: data?.permissions ? data.permissions : defaultValues.permissions,
    })
  }, [])

  useEffect(() => {
    if (id && !store.getState().settingsUsers?.getLoading) {
      dispatch(getUser({id: id})).then(rsp => {
        resetForm(rsp.payload.data)
      });
    } else if (!id && profileEdit && !store.getState().settingsUsers?.getLoading) {
      dispatch(getUser({id: authUser.id})).then(rsp => {
        resetForm(rsp.payload.data)
      });
    } else {
      resetForm({})
    }
  }, [id, profileEdit]);

  const generateApiToken = useCallback(() => {
    let token = uuid();
    setValue('api_key', token)
  }, []);

  const onSave = useCallback((data) => {
    if (!authUser.permissions?.includes(permissionNames.userManagement)) {
      delete data.email
    }
    if (id || profileEdit) {
      data.id = id ? id : authUser.id
      if (!data.password) {
        delete data.password
      }
      dispatch(updateUser(data));
    } else {
      dispatch(addUser(data));
    }
  }, [dispatch]);

  return <Box height={800}>
    { goBack ? <Stack direction='row'>
      <Button onClick={() => navigate(-1)}>Nazad</Button>
    </Stack> : null }
    <Container maxWidth={false} sx={{mt: 4, mb: 4}}>
      <Grid
        container
        spacing={0}
        style={{minHeight: '50vh'}}
      >
        <Grid item xs={6}>
          <form className="form" onSubmit={handleSubmit(onSave)}>
            <Grid item margin={1} xs={12}>
              <TextField
                fullWidth
                required
                autoComplete='no'
                label="Ime"
                InputLabelProps={{ shrink: !!watch('name') }}
                {...register('name', {required: true})}
              />
            </Grid>
            <Grid item margin={1} xs={12}>
              <TextField
                fullWidth
                required
                autoComplete='no'
                label="Prezime"
                InputLabelProps={{ shrink: !!watch('surname') }}
                {...register('surname', {required: true})}
              />
            </Grid>
            <Grid item margin={1} xs={12}>
              <TextField
                fullWidth
                required
                autoComplete='no'
                disabled={ !authUser.permissions?.includes(permissionNames.userManagement) }
                label="E-mail"
                type={'email'}
                InputLabelProps={{ shrink: !!watch('email') }}
                {...register('email', {required: true})}
              />
            </Grid>
            { authUser.permissions?.includes(permissionNames.userManagement) ? <Grid item margin={1} xs={12}>
              <TextField
                select
                SelectProps={{
                  multiple: true,
                  value: watch('permissions'),
                }}
                fullWidth
                label="Dozvole"
                defaultValue={[]}
                {...register('permissions')}
              >
                {
                  Object.entries(permissionNames).map(([key, value], id) => (
                    <MenuItem key={String(key)} value={String(value)}>
                      {String(value)}
                    </MenuItem>
                  )
                  )
                }
              </TextField>
            </Grid> : null}
            <Grid item margin={1} xs={12}>
              <TextField
                fullWidth
                autoComplete='no'
                label="Lozinka"
                type={hidePassword ? 'password' : 'text'}
                onKeyDown = {(e) => {
                  if (ALPHA_NUMERIC_DASH_REGEX.test(e.key) && !IGNORE_KEYS_LIST_PSW_HIDE.includes(e.key.toLowerCase())) {
                    setHidePassword(true)
                  }
                }}
                InputLabelProps={{ shrink: !!watch('password') }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => generatePassword()}><PasswordIcon/></IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('password')}
              />
            </Grid>
            { profileEdit ? <Grid item margin={1} xs={12}>
              <TextField
                fullWidth
                disabled={!watch('password')}
                label="Potvrda lozinke"
                type="password"
                {...register('password_confirm', {
                  disabled: !watch('password'),
                  validate: (val) => {
                    if (watch('password') !== val) {
                      return "Neispravna potvrda lozinke";
                    }
                  },
                })
                }
              />
            </Grid> : null }
            <Grid item margin={1} xs={12}>
              <Button
                onClick={() => generateApiToken()}
              >
                Generiraj novi API token
              </Button>
              {!!watch('api_key') &&
                <TextField
                  fullWidth
                  disabled
                  label="API token"
                  {...register('api_key')}
                  helperText="Token je vidlji samo jednom, sada, prije spremanja."
                />
              }

            </Grid>
            <Grid item xs={12}>
              <Stack direction="row-reverse">
                <Button type="submit" color="primary">{id ? 'Ažuriraj' : 'Spremi'}</Button>
              </Stack>
            </Grid>
          </form>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row-reverse">
            { authUser.permissions?.includes(permissionNames.userManagement) ?
              <>
                <AdvanceDialog
                  title={'Odjava korisnika'}
                  openDialog={forceLogoutUserDialog}
                  setOpenDialog={setForceLogoutUserDialog}
                  description={`Želite li odjaviti korisnika sa svih uređaja?`}
                  onConfirm={() => dispatch(forceLogoutUser({id: id}))}
                ></AdvanceDialog>
                <Button
                  variant="outlined"
                  onClick={() => setForceLogoutUserDialog(true)}
                  color="warning">Odjavi korisnika
                </Button>
              </> : null
            }
          </Stack>
        </Grid>
      </Grid>
    </Container>
  </Box>;
};

export default UserAddUpdate;
