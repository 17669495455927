/* eslint-disable no-restricted-globals */

let AppConfig = {
  // If REACT_APP_BACKEND_URL_OVERRIDE is set, use it as the backend URL
  // If not, decide the backend URL based on the NODE_ENV
  backendUrl: (() => {
    // Check for API URL override
    if (process.env.REACT_APP_BACKEND_URL_OVERRIDE) {
      let url = new URL(process.env.REACT_APP_BACKEND_URL_OVERRIDE);

      // If there's no port specified in the override, default to port 80
      if (!url.port) {
        url.port = 80;
      }

      return url.toString();
    }

    // If running in development mode, assume React server on 3000 and Flask backend on 5000
    return process.env.NODE_ENV === 'development'
      ? location.protocol + '//' + location.host.replace(':3000', ':5000')
      : location.protocol + '//' + location.host;
  })()
};

export default AppConfig;
