import React from 'react';
import PropTypes from 'prop-types';
// @mui
import {styled} from '@mui/material/styles';
import {Box, AppBar, Toolbar, IconButton, Tooltip, Menu, Avatar, MenuItem, ListItemIcon, Divider} from '@mui/material';

import {useDispatch} from 'react-redux';
import {userLogout} from '../../store/auth';

import MenuIcon from '@mui/icons-material/Menu';
import {Logout} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

/**
 *
 *
 * component taken from:
 * https://github.com/minimal-ui-kit/material-kit-react/blob/main/src/layouts/dashboard/header/index.js
 *
 */


// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 64;

const StyledRoot = styled(AppBar)(({theme}) => ({
//   ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({theme}) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({onOpenNav}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openInNewTab = (url) => {
    window.open(window.location.origin + '/' + url, '_blank');
  }
  return (
    <StyledRoot>
      <StyledToolbar>
        <IconButton
          onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: {lg: 'none'},
          }}
        >
          <MenuIcon/>
        </IconButton>

        <Box sx={{flexGrow: 1}}/>

        <Tooltip title="Postavke računa">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>☰</Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={() => navigate('profile/settings')}>
            <Avatar /> Moj Račun
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => openInNewTab('apispec')}>
            <ListItemIcon>
              <HelpOutlineIcon fontSize="small" />
            </ListItemIcon>
            Swagger
          </MenuItem>
          <MenuItem onClick={() => dispatch(userLogout())}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Odjava
          </MenuItem>
        </Menu>
      </StyledToolbar>
    </StyledRoot>
  );
}
